// extracted by mini-css-extract-plugin
export var newsFeed = "news-module--newsFeed--2hzAk";
export var newsBody = "news-module--newsBody--txfDG";
export var topSection = "news-module--topSection--UTjmM";
export var topSectionHero = "news-module--topSectionHero--8NKQB";
export var topSectionMeta = "news-module--topSectionMeta--zQwex";
export var topSectionMetaContentMobile = "news-module--topSectionMetaContentMobile--4xYC4";
export var topSectionMetaContent = "news-module--topSectionMetaContent--C6Lp4";
export var dateSeparator = "news-module--dateSeparator--4xuTb";
export var topSectionWrapper = "news-module--topSectionWrapper--lEYEy";
export var topSectionLogo = "news-module--topSectionLogo--blWqD";
export var topSectionLogoContainer = "news-module--topSectionLogoContainer--Ex-T2";
export var topSectionLogoItem = "news-module--topSectionLogoItem--Yb7-R";
export var topSectionBlueColumn = "news-module--topSectionBlueColumn--4Rhmg";
export var topSectionImage = "news-module--topSectionImage--4ReYe";
export var topSectionImageItem = "news-module--topSectionImageItem--zwZrV";
export var topSectionIntroText = "news-module--topSectionIntroText--FXCaZ";