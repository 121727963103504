import Column from 'components/column';
import Image from 'components/image';
import Link from 'components/link';
import Section from 'components/section';
import { graphql } from 'gatsby';
import Logo from 'images/svg/logo.inline.svg';
import React from 'react';
import Body from 'sections/body';
import NewsFeed from 'sections/news-feed';
import Spacing from 'sections/spacing';
import { HelmetDatoCms } from 'gatsby-source-datocms';

import * as styles from './news.module.scss';
import MatrixFigure from 'components/matrix-figure';
import LangSlug from 'components/lang-slug';

type NewsProps = {
  data: GatsbyTypes.NewsPageQueryQuery;
};

const News: React.FC<NewsProps> = ({ data }) => {
  const {
    news,
    allDatoCmsNews: { latestNews },
  } = data;
  if (!news) return null;

  return (
    <>
      <HelmetDatoCms seo={news.seoMetaTags} />
      <LangSlug langList={news?.langSlugs as GatsbyTypes.LangSlugFragment[]} model={'news'} />
      <Section
        className={styles.topSection}
        sectionClass={styles.topSectionHero}
        backgroundColor={'light'}
      >
        <div className={styles.topSectionBlueColumn}>
          <Column xl={4} lg={4} md={4} sm={2} xs={2} />
        </div>
        <div className={styles.topSectionWrapper}>
          <Column className={styles.topSectionLogoContainer} xl={2} lg={2} md={2} sm={1} xs={2}>
            <Link
              className={styles.topSectionLogo}
              title={'Hem'}
              link={{ model: { apiKey: 'start_page' } }}
            >
              <Logo className={styles.topSectionLogoItem} />
            </Link>
          </Column>
          <Column xl={0} lg={0} md={0} sm={0} xs={10} />
          <Column className={styles.topSectionMeta} xl={2} lg={2} md={2} sm={1} xs={2}>
            <label className={styles.topSectionMetaContent}>
              {news.category?.title}
              {news.meta?.publishedAt && (
                <>
                  <span className={styles.dateSeparator}>|</span>
                  {news.meta.publishedAt}
                </>
              )}
            </label>
          </Column>
        </div>
      </Section>
      <Section className={styles.topSection} backgroundColor={'Light'}>
        <div className={styles.topSectionBlueColumn}>
          <Column xl={4} lg={4} md={4} sm={2} xs={2} />
        </div>
        <Column xl={8} lg={8} md={8} sm={10} xs={0} />
        <Column
          className={styles.topSectionImage}
          xl={{ offset: 1, width: 4 }}
          lg={{ offset: 1, width: 4 }}
          md={5}
          sm={{ offset: 1, width: 10 }}
          xs={{ offset: 2, width: 10 }}
        >
          {news.featuredImage ? (
            <Image className={styles.topSectionImageItem} {...news.featuredImage} />
          ) : (
            <MatrixFigure size={'large'} />
          )}
        </Column>
        <Column
          className={styles.topSectionIntroText}
          xl={6}
          lg={6}
          md={6}
          sm={{ offset: 3, width: 8 }}
          xs={{ offset: 2, width: 10 }}
        >
          {news.title && <h1 dangerouslySetInnerHTML={{ __html: news.title }} />}
          {news.introText && <div dangerouslySetInnerHTML={{ __html: news.introText }} />}
        </Column>
      </Section>
      <Body className={styles.newsBody} data={news.bodyText as GatsbyTypes.NewsBodyTextFragment} />
      <Spacing insetColumn backgroundColor={'light'} />
      {latestNews && (
        <NewsFeed
          className={styles.newsFeed}
          data={latestNews as GatsbyTypes.DatoCmsNews[]}
          backgroundColor={'mint'}
          linkText={`Fler nyheter`}
          link={
            {
              model: {
                apiKey: 'news_archive',
              },
            } as GatsbyTypes.DatoCmsUnionForDatoCmsNewsFeedLink
          }
        />
      )}
    </>
  );
};

export default News;

export const query = graphql`
  query NewsPageQuery($id: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allDatoCmsNews(
      limit: 4
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { id: { ne: $id } }
    ) {
      latestNews: nodes {
        id: originalId
        title
        slug
        meta {
          publishedAt: firstPublishedAt(formatString: "YYYY-MM-DD")
        }
        category {
          title
        }
        model {
          apiKey
        }
        featuredImage {
          ...Image
        }
      }
    }
    news: datoCmsNews(id: { eq: $id }) {
      id
      title
      seoMetaTags {
        tags
      }
      langSlugs {
        ...LangSlug
      }
      featuredImage {
        ...Image
      }
      introText
      meta {
        publishedAt: firstPublishedAt(formatString: "YYYY-MM-DD")
      }
      category {
        title
      }
      bodyText {
        ...NewsBodyText
      }
    }
  }
`;
